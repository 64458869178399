import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import HeaderBanner from "../components/headerbanner"
import "../styles/style.scss"
import "../styles/csstyle.scss"

export default function ErrorPage() {
  return (
    <div>
      <Helmet>
        <title>GoFloaters Customer Stories</title>
      </Helmet>
      <Layout>
        <HeaderBanner>
          <h1>GoFloaters Customer Stories</h1>
        </HeaderBanner>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/01.jpg"
                style={{ width: "100%" }}
              ></img>
              <br /> <br />
            </div>
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/02.jpg"
                style={{ width: "100%" }}
              ></img>{" "}
              <br /> <br />
            </div>
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/03.jpg"
                style={{ width: "100%" }}
              ></img>{" "}
              <br /> <br />
            </div>
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/04.jpg"
                style={{ width: "100%" }}
              ></img>{" "}
              <br /> <br />
            </div>
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/05.jpg"
                style={{ width: "100%" }}
              ></img>{" "}
              <br /> <br />
            </div>
            <div className="col-md-6">
              <img
                src="https://assets.gofloaters.com/worqpass/feedback/06.jpg"
                style={{ width: "100%" }}
              ></img>{" "}
              <br /> <br />
            </div>
          </div>
          <br />
          <br />
        </div>
      </Layout>
    </div>
  )
}
